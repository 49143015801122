body {
  margin: 0;
  font-family: "DMSans-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  background: #010101;
  height: 100%;
}


.btn{
  background: rgba(0,0,0,0);
  color: #fff;
  padding: 12px 30px;
  /*padding: 9px 18px 9px 30px;*/
  border: 1px solid #3182CE;
  height: 48px;
  line-height: 48px;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 18px;

  .btn-text{
    filter: blur(0);
  }



  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 36px);
    height: calc(100% - 18px);
    border-radius: 99px;
    background: #3182CE;
    //border-color: #3182CE;
    filter: blur(12px);
    transition: all .3s;
  }

  &:hover{
    &::before{
      width: 100%;
      height: 100%;
      background: #3182CE !important;
      color: #fff !important;
      filter: blur(16px) !important;
    }

    .btn-text{
      filter: blur(0);
      opacity: .8;
    }
  }

  &:active{
    &::before{
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #3182CE;
      filter: blur(8px);
    }
    .btn-text{
      filter: blur(0);
      opacity: .8;
    }
  }
}

.disabled-btn{
  width: 100%;
  background: rgba(0,0,0,0);
  color: rgba(255,255,255, .5);
  padding: 12px 30px;
  height: 48px;
  line-height: 48px;
  /*padding: 9px 18px 9px 30px;*/
  //cursor: disabled;
  cursor: not-allowed;
  border-radius: 50px;
  border: 1px solid rgba(49, 130, 206, 0.30);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 18px;

  .btn-text{
    filter: blur(0);
  }

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 36px);
    height: calc(100% - 18px);
    border-radius: 99px;
    background: rgba(49, 130, 206, 0.50);
    //border-color: #3182CE;
    filter: blur(10px);
    transition: all .3s;
  }
}

.ant-drawer .ant-drawer-header{
  display: none;
}

.ant-drawer .ant-drawer-content{
  background: #161616;
  font-size: 16px;
  //font-style: normal;
  color: #fff;
  font-weight: 500;
}

.ant-drawer .ant-drawer-wrapper-body .ant-drawer-body{
  padding: 20px 16px;
}

.default-button{
  width: 100%;
  border-radius: 12px;
  background: rgba(74, 85, 104, 0.30);
  //border-color: rgba(74, 85, 104, 0.30);
  border: 0;
  color: #718096;

  &:hover{
    background: rgba(74, 85, 104, 0.50);
  }

  &:active{
    background: rgba(74, 85, 104, 0.70);
  }
}

.default-button:not(:disabled):not(.ant-btn-disabled):hover{
  color: #718096;
}

.default-button:not(:disabled):not(.ant-btn-disabled):active{
  color: #718096;
}

.default-button:not(:disabled):focus-visible{
  outline: 0px;
  outline-offset: 0px;
}

.ant-modal-mask{
  background-color: rgba(0,0,0,.6) !important;
}

.ant-modal-content{
  border-radius: 24px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.success-modal-mask{
  //opacity: 0.5;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(40px);
  background: linear-gradient(180deg, rgba(0, 68, 148, 0.50) 9.34%, rgba(97, 82, 0, 0.00) 100%, rgba(0, 91, 97, 0.00) 100%);
}

.ant-modal .success-modal-content{
  text-align: center;
  background: rgba(0,0,0,0);
  box-shadow: 0px 0px 0px;

  .success-text{
    margin-top: 40px;
    margin-bottom: 64px;
    font-size: 32px;
    background: linear-gradient(94deg, #E3E7B8 4.71%, #FFF 86.46%);
    font-weight: 900;
    font-family: "DMSans-Bold";
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .btn{
    width: 280px;
    margin: 0 auto;
  }
}

.ant-modal-header{
  margin-bottom: 0 !important;
}


