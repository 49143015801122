.Welcome-background {
    width: 100%;
    height: 100vh;
    background: url("../../assets/homeBg.png") no-repeat;
    background-position: center;
    background-size: contain;

}

.Welcome-image {
    text-align: center;
    margin-top: 77px;
}

.Welcome-image img {
    width: 446px;
    height: 414px;
}

.Welcome-title {
    margin-top: -257px;
    text-align: center;
}

.Welcome-title span {
    display: block;
    margin: 0 auto;
    width: 944px;

    font-family: "DMSans-Bold";
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 3.2px;

    background: linear-gradient(94deg, #E3E7B8 4.71%, #FFF 86.46%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Welcome-subtitle {
    margin-top: 20px;
    text-align: center;
}

.Welcome-subtitle span {
    color: #9CA3AF;
    text-align: center;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
}

.Welcome-btn-container {
    //text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 97px;

    .btn{
        width: 417px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: 700;
    }
}

.home-transfer{
    display: flex;
    //align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.ant-message-notice-error{

}

// #2F855A

.ant-message .ant-message-notice-success .ant-message-notice-content, .ant-message .ant-message-notice-error .ant-message-notice-content{
    background: #2F855A;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.ant-message .ant-message-notice-error .ant-message-notice-content{
    background: #C53030;
}

.ant-message .ant-message-notice-wrapper .ant-message-success>.anticon,
.ant-message .ant-message-notice-wrapper .ant-message-error>.anticon{
    color: #fff;
}
