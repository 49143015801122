.transfer-container{
  width: 446px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 20px;
  //height: 437px;
  //mix-blend-mode: color-dodge;
}

.private-container{
  background: url("../../../assets/privateBg.png") no-repeat;
  background-size: 100% 100%;
  //mix-blend-mode: color-dodge;
}

.public-container{
  //background: #161616;
  //border: 1px solid rgba(255, 255, 255, 0.10);
  //box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: rgba(48, 55, 82, 0.80);
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.25);
  //backdrop-filter: blur(40px);
}

.switch-container{
  display: inline-flex;
  cursor: pointer;
  padding: 2px;
  box-sizing: border-box;
  width: 50px;
  height: 28px;
  border-radius: 999px;
}

.checked-switch{
  justify-content: flex-end;
  background-color: #3182CE;
}

.unchecked-switch{
  background-color: #CBD5E0;
  justify-content: flex-start;
}
.switch-input{
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: 1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

.switch-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
}

.transfer-switch{
  display: flex;
  align-items: center;

  &-title{
    font-size: 20px;
    font-weight: 500;
    margin-left: 8px;
  }
}

.alert-content{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  background: rgba(49, 130, 206, 0.20);
  box-sizing: border-box;
  cursor: pointer !important;
}

.transfer-box{
  border-radius: 12px;
  padding: 8px 16px 12px 16px;
  box-sizing: border-box;

  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left{
      display: flex;
      align-items: center;
    }
  }


  &-label{
    color: #AEBED4;
    font-weight: 500;
  }
  &-value{
    color: #FFF;
    font-weight: 500;
    margin-left: 14px;
  }
  &-balance{
    color: #AEBED4;
    font-size:12px;
  }

  &-form{
    display: flex;
    margin-top: 16px;
    .ant-input-number, .ant-input{
      background: rgba(0,0,0,0);
      border:0;
      color: #D1D5DB;
      font-size: 18px;
      font-weight: 700;
      font-family: "DMSans-Bold";
      width: 100%;
      flex: 1;

      &:focus-within{
        outline: 0;
        box-shadow: 0 0 0;
      }
      &::placeholder{
        color: rgba(209, 213, 219, 0.5);
      }

      .ant-input-number-input{
        color: #D1D5DB;

        &::placeholder{
          color: rgba(209, 213, 219, 0.5);
        }
      }
    }

   .ant-input-number .ant-input-number-handler-wrap{
      background: rgba(0,0,0,0);
      border:0;

     .ant-input-number-handler{
       display: none;
     }
    }
  }

  .private-form{
    border: 0.6px solid var(--Color, #3182CE);
    background: linear-gradient(0deg, rgba(49, 130, 206, 0.20) 0%, rgba(49, 130, 206, 0.20) 100%), #000;
  }

  &-select{
    display: flex;
    margin-left: 8px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.20);
    padding: 6px 11px;
    font-weight: 500;
    font-size: 14px;
    justify-content: space-around;
    width: 134px;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
  }

  &-mid{
    display: flex;
    justify-content: center;
    margin: 16px 0;
  }

  &-btn{
    width: 100%;
    margin-top: 24px;
    .btn{
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.public-transfer{
  border: 0.6px solid rgba(229, 231, 235, 0.50);
  background: rgba(41, 41, 41, 0.50);

  &:hover{
    border-color:rgba(229, 231, 235, 0.80);
    background: rgba(75, 75, 75, 0.50);
  }

  &:active{
    border-color: rgba(229, 231, 235, 0.50);
    background: rgba(75, 75, 75, 0.50);
  }
}

.private-transfer{
  border: 0.6px solid var(--Color, #3182CE);
  background: linear-gradient(0deg, rgba(49, 130, 206, 0.20) 0%, rgba(49, 130, 206, 0.20) 100%), #000;

  &:hover{
    border-color:#3D99EE;
    background: linear-gradient(0deg, rgba(61, 153, 238, 0.30) 0%, rgba(61, 153, 238, 0.30) 100%), #000;
  }

  //&:active{
  //  border-color: rgba(61, 153, 238, 0.80);
  //  background: linear-gradient(0deg, rgba(61, 153, 238, 0.25) 0%, rgba(61, 153, 238, 0.25) 100%), #000;
  //}

  .transfer-box-private{
    border-radius: 20px;
    background: rgba(61, 153, 238, 0.01);
    box-shadow: 0 0 8px 0 rgba(85, 133, 255, 0.60) inset;
    margin-left: 4px;
    padding: 3px 12px 3px 8px;
    display: flex;
    color: #3D99EE;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
  }
}



.invalid-transfer{
  border-radius: 12px;
  border: 0.6px solid #E14848;
  background: linear-gradient(0deg, rgba(225, 72, 72, 0.20) 0%, rgba(225, 72, 72, 0.20) 100%), #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
//兼容火狐
input[type='number'] {
  -moz-appearance: textfield;
}

.token-modal{
  .ant-modal-content, .ant-modal-header{
    background: #2C2D3A;
    .ant-modal-title, .ant-modal-close-icon, .address-list-title{
      color: #FFF;
    }

    .address-list{
      background: rgba(0,0,0,0);

      &-item-title{
        color: #FFF;
      }
    }
  }
}

.token-item:hover{
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.10);
}

.account-box{
  text-align: center;
  border-radius: 12px;
  padding: 56px 0;
  border: 0.6px solid rgba(49, 130, 206, 0.50);
  background: rgba(49, 130, 206, 0.10);
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-top: 32px;
}

.account-box-private{
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-account{
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 12px 0 12px 20px;
    background: rgba(74, 85, 104, 0.30);
    flex: 1;
    width: 100%;
  }

  &-icon{
    border-radius: 50%;
    border: 1px solid #3182CE;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    .anticon{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: rgba(0,0,0,0);
      left: 0;
      //border-color: #3182CE;
      filter: blur(0px);
      transition: all .3s;
    }

    &:hover{
      &::before{
        left: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        background: #3182CE !important;
        color: #fff !important;
        filter: blur(16px) !important;
      }
    }

    &:active{
      &::before{
        left: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background: #3182CE;
        filter: blur(8px);
      }
    }

  }
}

.error-box, .success-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(225, 72, 72, 0.30);
  background: rgba(225, 72, 72, 0.01);
  box-shadow: 0px 0px 8px 0px #E14848 inset;
  padding: 2px 12px 2px 8px;
  color: #E14848;
  font-size: 14px;
}

.success-box{
  border-radius: 20px;
  border: 1px solid var(--yellow-30, rgba(49, 130, 206, 0.30));
  background: var(--yellow-1, rgba(49, 130, 206, 0.01));
  box-shadow: 0px 0px 8px 0px #5585FF inset;
  color: #3182CE;
}


.mask{
  position: absolute;
  width: 100vw;
  min-width: 1200px;
  //height: 100vh;
  z-index: 10;
  top: 0px;
  left: 0px;
  display: flex;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(0, 68, 148, 0.50) 0%, rgba(0, 0, 0) 100%);
  backdrop-filter: blur(40px);

}

@keyframes fadeIn
{
  0%   {opacity: 0}
  25%  {opacity: 0.25}
  50%  {opacity: 0.5}
  100% {opacity: 1}
}


.fadeIn{
  //opacity: 1; /* 初始opacity为0，即完全透明 */
  //transition: opacity 10s ease-in-out;

  position: absolute;
  top: 20%;
  width: 800px;
  left: calc(50% - 400px);
  text-align: center;
  //width: 400px;
  animation: fadeIn 2s; /** 动画属性 */
}

@keyframes successFadeIn
{
  0%   {opacity: 0}
  25%  {opacity: 0.25}
  50%  {opacity: 0.5}
  100% {opacity: 1}
}
.successFadeIn{
  //opacity: 1; /* 初始opacity为0，即完全透明 */
  //transition: opacity 10s ease-in-out;
  position: absolute;
  top: 20%;
  width: 360px;
  left: calc(50% - 180px);
  text-align: center;
  //width: 400px;
  animation: successFadeIn 2s; /** 动画属性 */

  .btn{
    width: 280px;
    margin: 0 auto;
  }
}

.success-text{
  margin-top: 40px;
  margin-bottom: 64px;
  font-size: 32px;
  background: linear-gradient(94deg, #E3E7B8 4.71%, #FFF 86.46%);
  font-weight: 900;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

